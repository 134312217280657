<template>
  <transition name="fade">
    <div v-if="isShown" class="popup popup--dailog">
      <div class="popup__overlay">
      </div>
      <div class="popup__enter">
        <div class="popup__content">
          <button class="popup__close" @click="$emit('closeRequest')" type="button">
            <svg>
              <use xlink:href="@/assets/images/sprites.svg#icon-close"></use>
            </svg>
          </button>
          <div class="popup__title">Заявка {{isTradeIn ? 'по трейд-ин' : 'на тест-драйв' }}</div>
          <div class="popup__text">Мы обязательно с Вами свяжемся</div>
          <form class="popup__form requisition-form" @submit.prevent="callRequest">
            <fieldset class="popup__fieldset" :class="{ 'is-error': v$.name.$error }">
              <legend class="popup__legend">Имя</legend>
              <input class="popup__input" v-model="name" :disabled="nameDisabled" type="tel" name="name"
                placeholder="Иванов Иван Иванович" />
              <div class="popup__error">Неверный формат имени</div>
            </fieldset>
            <fieldset class="popup__fieldset" :class="{ 'is-error': v$.phone.$error }">
              <legend class="popup__legend">Телефон</legend>
              <input class="popup__input" v-model="phone" :disabled="phoneDisabled" type="tel" name="phone"
                placeholder="+7***" />
              <div class="popup__error">Неверный формат телефона</div>
            </fieldset>
            <div class="popup__button">
              <button class="button button--requisition" type="submit" :disabled="loading">{{isTradeIn ? 'Трейд-ин' : 'Тест-драйв' }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import * as links from '../store/links';

export default {
  name: 'CallRequest',
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    carId: {
      type: Number,
      default: 0
    },
    isTradeIn: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  data() {
    return {
      phone: '',
      name: '',
      url: window.location.href,
      loading: false,
      phoneDisabled: false,
      nameDisabled: false
    };
  },
  computed: {
    isShown() {
      return this.show;
    }
  },
  validations: {
    phone: { required },
    name: { required }
  },
  methods: {
    async callRequest() {

      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) return;

      this.loading = true;
      console.info('Call::callRequest');
      this.axios.post(
        `${links.API}/back-call/test-drive`,
        {
          name: this.name,
          phone: this.phone,
          car_id: this.carId
        },
        {
          loading: { message: 'Trying to login...' }
        }
      )
        .then(async () => {
          this.loading = false;
          this.$emit('close');
          if (this.$metrika) {
            this.$metrika.reachGoal('card_testdrive_fs');
          }
        })
        .catch((error) => {
          console.error(error);
          this.$emit('closeRequest');
          this.$store.dispatch('auth/checkAuth', error);
        });

    }
  }
};
</script>
