<template>
  <router-view/>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      savedQueryParams: null,
    };
  },
  created() {
    this.saveInitialQueryParams();

    this.$router.afterEach(() => {
      this.applySavedQueryParamsToUrl();
    });
  },
  methods: {
    saveInitialQueryParams() {
      const urlParams = new URLSearchParams(window.location.search);
      this.savedQueryParams = new URLSearchParams(urlParams);
    },
    applySavedQueryParamsToUrl() {
      if (!this.savedQueryParams) return;

      const currentUrl = new URL(window.location.href);
      const newParams = new URLSearchParams(this.$route.query);

      if (Array.from(newParams).length > 0) {

        this.savedQueryParams.forEach((value, key) => {
          if (key !== 'rclass' && !newParams.has(key)) {
            newParams.set(key, value);
          }
        });

        const newUrl = `${currentUrl.origin}${currentUrl.pathname}?${newParams.toString()}`;

        if (newUrl !== window.location.href) {
          window.history.replaceState(null, '', newUrl);
        }
      }
    }
  }
};
</script>
